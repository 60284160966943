html, body {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  background-size: contain;
  font-size: 1.175em;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100%;

  background: #F5F5F5;  /* fallback for old browsers */
  
}
.App {
  text-align: center;
}



/* .loading::after {
  content: "Analyzing..." !important
}

.create-text::after {
  content: "Start Replacing"
} */

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.3; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.3; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}

.fa-redo-alt {
  margin-right: 0.5em;
}

h1 {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.5;
  display: inline;
}

a {
  color: #333;
}

a:hover {
  text-decoration: none;
}

#story-container a:hover {
  color: #333;
  border-bottom: 1px solid;
}

.sign-in-prompt {
  font-size: 1rem;
}

.fa-copy {
  font-size: 1.25rem;
  line-height: 1.3;
  margin-left: 0.25rem;
}

.author-attr {
  font-size: 0.75em;
  margin-bottom: 0.5em;
  color: #999;
}

/* .dropdown-item:hover {
  color: #000 !important;
  background-color: #fff;
} */

#input-container .input-wrapper  {
  opacity: 0;
  height: 0;
}

.preset-search-container {
  max-width: 200px;
}

.nav-link-box {
  /* border: 1px solid rgba(255,255,255,0.5); */
  border-radius: 4px;
  margin-left: 0.75em;
  box-shadow: 0 0 0 1pt rgba(0,0,0,0.2);
}

.input-wrapper:first-child {
  opacity: 1 !important;
  height: 100% !important;
}

#counter {
  float: right;
}

.container {
  height: 100vh;
}

.navbar {
  max-width: 1024px;
  margin: auto;
  padding: 0.75em 0 0.75em 0;
}

.nav-bg {
  background-color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  height: 69px;
  border-bottom: 1px solid #ccc;
}

.navbar-brand {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  /* border: 2px solid #fff !important; */
  display: block;
  padding: 0.25em 0.5em 0.25em 0em;
}

/* #navbarNavDropdown {
  padding: 1rem;
} */

.spacer {
  height: 1.5rem;
}

/* .logo-bold {
  font-weight: 800;
} */
/* 
.logo-strike {
  text-decoration: line-through;
} */

.nav-link {
  font-size: 0.875em;
}

.wrapper {
  max-width: 1024px;
  padding: 1.5rem;
  margin: auto;
  min-height: 410px;
  border: 1px solid #ccc;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

/* .wrapper:focus-within {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
} */

.sign-up-link {
  text-decoration: underline
}

.signup-container {
  max-width: 220px;
}

.story-wrapper {
  max-width: 1024px;
  padding: 1.5rem;
  /* min-height: 166px; */
  margin: auto;
  border: 1px solid #ccc;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.swatch {
  width: 36px !important;
  height: 36px !important;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.swatch-preview {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #fff;
}

.icon-preview {
  width: 36px !important;
  height: 36px !important;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
}

.icon-preview i {
  font-size: 1.5rem;
  line-height: 1.4;
  color: #fff;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  border: 1px solid #fff;

}

/* --- Profile Stuff --- */

.profile-pic {
  height: 40px;
  position: absolute;
  top: 0px;
  width: 40px;
  right: 25px;
  border-radius: 20px;
  text-align: center;
}

.profile-pic i {
  font-size: 1.375rem;
  color: #fff;
}

.profile-eyebrow {
  font-size: 0.75em;
  text-transform: uppercase;
  color: #999;
}

.profile-subtext {
  font-size: 0.875rem;
  line-height: 1.6;
  display: none;
}

.profile-subtext::first-letter {
  text-transform: capitalize;
}


.profile-pic-feed {
  height: 40px;
  float: left;
  width: 40px;
  border-radius: 20px;
  text-align: center;
  margin: 0.25em 0.75em 0 0;
}

.profile-name {
  line-height: 2;
}



.profile-pic-feed i {
  font-size: 1.375rem;
  color: #fff;
}

.profile-pic-profile {
  height: 60px;
  float: left;
  width: 60px;
  border-radius: 4rem;
  text-align: center;
  margin: 0.25em 0.75em 0 0;
}

.profile-pic-profile i {
  font-size: 2.25rem;
  color: #fff;
}

.table {
  margin-top: 2rem;
  font-size: 0.875rem;
}


/* -------------------*/

.dropdown-item i {
  font-size: 2rem;
}

.color-drop {
  height: 30px;
  padding: 1em;

}

option {
  font-family: 'Font Awesome 5 Brands' , 'arial' !important;
}


.story-summary {
  color: #666;
}

.input-labels {
  font-size: 0.875em;
}


.user-dropdown {
  right: 10px;
  left: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
 }

 .user-dropdown::-webkit-scrollbar {
   width: 0 !important;
 }

 .search-dropdown {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
 }

 .search-dropdown::-webkit-scrollbar {
   width: 0 !important;
 }

 .search-dropdown i {
   font-size: 1rem;
   width: 2rem;
   text-align: center;
 }

 .search-dropdown .dropdown-item {
   padding-left: 0.5rem !important;
 }


 .main-container {
   width: 100vw !important;
   margin: 0 !important;
   padding: 0 !important;
 }

#story-container {
  font-size: 1rem;
}

.word-input {
  border-style: none;
  border-bottom: 1px solid #533790;
  padding-left: 0.25rem;
}

.word-input:focus {
  outline: none;
  box-shadow: none;
}

.col-md-12 {
  padding: 0;
}

.row {
  margin: 0;
}

.input-label {
  font-size: 1rem;
}

label {
  font-size: 1rem;
}

.dropdown-menu {
  max-height: 180px;
  overflow-y: scroll;
}

.dropdown-menu i {
  pointer-events: none;
}

/* .login-links {
  display: inline;
} */

.nav-mobile {
  display: none;
}

.auth-inputs {
  max-width: 360px;
}

.card-columns {
  -webkit-column-count: 5;
          column-count: 5;
}

.gif-focused {
  outline-color: lightblue;
  outline-style: solid;
  outline-width: thick;
  opacity: 1 !important;
}

.gif-img {
  opacity: 0.5;
}

.gif-feed img {
  width: 100%;
  height: auto;
}

.detail-thumb {
  width: 80px;
  height: auto;
  float: left;
  margin-right: 1em;
}

.detail-thumb img {
  width: 80px;
  margin-top: 0.25em;
}

.detail-header {
  overflow: auto;
}

@media screen and (max-width: 992px) {
  .wrapper {
    max-width: 95%;
  }
  .navbar {
    padding: 1em;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }
  .nav-link {
    font-size: 1.5em;
  }
  .nav-mobile {
    display: inline;
  }
  .card-columns {
    -webkit-column-count: 4;
            column-count: 4;
  }
  .story-desc {
    margin-top: 1em;
  }
}


