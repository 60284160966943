html, body {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  background-size: contain;
  font-size: 1.175em;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100%;

  background: #F5F5F5;  /* fallback for old browsers */
  
}